<template>
  <AuthLayout :bgImage="require('@bd/assets/images/image-woman.jpg')">
    <template #logo>
      <div class="text-center mt-auto">
        <img :src="require('@bd/assets/images/immoby-horizontal-logo.svg')" />
      </div>
    </template>
    <template #main>
      <AuthLogin @login="onLogin" :userType="UserType.Admin" />
    </template>
  </AuthLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { AuthLayout, AuthLogin, UserType } from '@bd/components'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Login',
  components: { AuthLayout, AuthLogin },
  setup() {
    const router = useRouter()
    const onLogin = () => router.push({ path: '/' })

    return { onLogin, UserType }
  },
})
</script>
