
import { defineComponent } from 'vue'
import { AuthLayout, AuthLogin, UserType } from '@bd/components'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Login',
  components: { AuthLayout, AuthLogin },
  setup() {
    const router = useRouter()
    const onLogin = () => router.push({ path: '/' })

    return { onLogin, UserType }
  },
})
